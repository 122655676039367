const MESSAGE_API = 'MESSAGE_API';
const CLEAR_MESSAGE_API = 'CLEAR_MESSAGE_API';

export const addMessage = (message, status) => ({
    type: MESSAGE_API,
    payload: {message, status},
})

export const clearMessage = () => ({
    type: CLEAR_MESSAGE_API
})

export const getMessages = (state = {}, action = {}) => {
    switch (action.type) {
        case MESSAGE_API:
            return { message: action.payload };
        case CLEAR_MESSAGE_API:
        default:
            return { message : null};
    }
}