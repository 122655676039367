import React from "react";
import { Alert, AlertTitle } from '@material-ui/lab';
import * as PropTypes from "prop-types";
import Fade from "@material-ui/core/Fade";

class ToastAutoHide extends React.Component
{
    static propTypes = {
        titre: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        supprimerMessage: PropTypes.func.isRequired,
        severite: PropTypes.string.isRequired
    }

    constructor() {
        super();

        this.state = {
            show: true
        };
        setTimeout(() => this.onClose(), 10000);
    }

    onClose() {
        this.setState({show: false});

        const callbackFonction = this.props.supprimerMessage;
        setTimeout(() => callbackFonction(), 2000);
    }

    render() {
        return (
            <Fade onClose={this.onClose.bind(this)} in={this.state.show} style={{zIndex: '1000'}}>
                <Alert severity={this.props.severite} style={{zIndex: '1000'}}>
                    <AlertTitle>{this.props.titre}</AlertTitle>
                    {this.props.message}
                </Alert>
            </Fade>
        );
    }
}

export default ToastAutoHide;