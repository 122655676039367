import React from "react";
import {connect} from "react-redux";
import * as PropTypes from "prop-types";
import {clearMessage, getMessages} from "../services/messagesRedux";
import ToastAutoHide from "../components/ToastAutoHide";

class ToastMessage extends React.Component
{
    static propTypes = {
        message: PropTypes.object,
    }

    constructor() {
        super();

        this.state = {
            messages: []
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.message !== prevProps.message && this.props.message) {
            const uniqueId = Math.floor(Math.random() * 10000);
            const messages = this.state.messages;
            messages[uniqueId] = this.props.message;
            this.setState({messages});
            clearMessage();
        }
    }

    supprimerMessage = index => {
        const messages = this.state.messages;
        messages.splice(index);
        this.setState({messages});
    }

    renderTousLesMessages = () => {
        return this.state.messages.map((message, index) => {
            let titre = '';
            let severite = '';
            const status = parseInt(message.status);
            if (status >= 200 && status < 300) {
                titre = 'Succès'
                severite = 'success'
            } else if (status >= 400 && status < 500) {
                titre = 'Attention'
                severite = 'warning'
            } else {
                    titre = 'Erreur'
                    severite = 'error'
            }

            return (
                <ToastAutoHide titre={titre} severite={severite} message={message.message} key={index} supprimerMessage={() => this.supprimerMessage(index)} />
            )
        })
    }

    render() {
        if (!this.state.messages) {
            return null;
        }
        return (
            <div
                aria-live="polite"
                aria-atomic="true"
                style={{
                    position: 'relative',
                    height: '0',
                    zIndex: '1000'
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: '1000'
                    }}
                >
                    {this.renderTousLesMessages()}
                </div>
            </div>
        );
    }
}

export default connect(
    state => {
        const {
            message
        } = state.messages.messagesStore
        return { message };
    },
    {
        getMessages
    }
)(ToastMessage);