import React, {useEffect} from "react";
import {getAPI, reinitializeGetAPI} from "../services/getApiRedux";
import {connect} from "react-redux";
import {
    API_SERIES,
    API_SERIES_EP,
    CONTEXT_SERIES_EP,
    CONTEXT_SERIES_EP_ID,
    CONTEXT_SERIES_ID
} from "../constant/contextApi";
import Card from "@material-ui/core/Card";
import {CardContent, Grid } from "@material-ui/core";
import moment from "moment";
import 'moment/locale/fr';
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";

function SeriesEp(props) {

    const { id } = props.match.params;

    useEffect(() => {
        props.getAPI(API_SERIES+'/'+id, CONTEXT_SERIES_ID);
    }, []);

    const renderCards = () => {
        if (!props.data) {
            return null;
        }

        return props.data.map(episode => {

            moment.locale('fr');

            return (
                <Grid item xs={6} md={3} key={episode.serie_tv.titre}>
                    <Card>
                        <CardContent>
                            <p>{episode.serie_tv.titre}</p>
                            {episode.serie_tv.image && <p style={{textAlign: 'center'}}><img src={episode.serie_tv.image} style={{width: '100%', maxWidth: '150px'}} alt={episode.serie_tv.titre} /></p>}
                            <p>
                                <u>Prochaines diffusions :</u>
                            </p>
                            <ul>
                                <li><b>{episode.chaine} </b>{moment(episode.debut, "YYYY-MM-DD HH:mm:ss").format("ddd D MMM HH:mm")}{' '}
                                    - {moment(episode.fin, "YYYY-MM-DD HH:mm:ss").format("ddd D MMM HH:mm")}</li>
                                {episode.serie_tv.diffusions && episode.serie_tv.diffusions.map(diffusion => {
                                    return (
                                        <li key={diffusion.debut}><b>{diffusion.chaine} </b>{moment(diffusion.debut, "YYYY-MM-DD HH:mm:ss").format("ddd D MMM HH:mm")}{' '}
                                        - {moment(diffusion.fin, "YYYY-MM-DD HH:mm:ss").format("ddd D MMM HH:mm")}</li>
                                    );
                                })}
                            </ul>
                        </CardContent>
                    </Card>
                </Grid>
            )
        });
    }

    return (
        <>
            <Button variant="contained" color="primary" style={{marginBottom: '4rem'}}><Link to="/series" style={{color: 'white', textDecoration: 'none'}}>Retourner à la liste des séries</Link></Button>
            <Grid container spacing={3}>
                {renderCards()}
            </Grid>
        </>
    )
}

export default connect(
    state => {
        const {
            data,
            isLoading,
            isLoaded,
            erreur
        }  = state.api.seriesIdStore;
        return { data, isLoading, isLoaded, erreur };
    },
    {
        getAPI,
        reinitializeGetAPI,
    }
)(SeriesEp);