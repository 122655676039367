import React, {useEffect} from "react";
import {getAPI, reinitializeGetAPI} from "../services/getApiRedux";
import {connect} from "react-redux";
import {API_FILMS, API_SERIES_EP, CONTEXT_FILMS, CONTEXT_SERIES_EP} from "../constant/contextApi";
import Card from "@material-ui/core/Card";
import {CardContent, Grid } from "@material-ui/core";
import moment from "moment";
import 'moment/locale/fr';
import {Link} from "react-router-dom";

function SeriesEp(props) {
    useEffect(() => {
        props.getAPI(API_SERIES_EP, CONTEXT_SERIES_EP);
    }, []);

    const renderCards = () => {
        if (!props.data) {
            return null;
        }
        return props.data.map(serie => {

            moment.locale('fr');

            return (
                <Grid item xs={6} md={3} key={serie.titre + serie.debut}>
                    <Card>
                        <CardContent>
                            <p>{serie.titre}</p>
                            {serie.image && <p style={{textAlign: 'center'}}><img src={serie.image} style={{width: '100%', maxWidth: '150px'}} alt={serie.titre} /></p>}
                            <p>
                                <u>Prochaine diffusion :</u> {moment(serie.debut, "YYYY-MM-DD HH:mm:ss").format("ddd D MMM HH:mm")}
                            </p>
                            <p><Link to={'/series-ep/' + serie.id} >Voir les prochaines diffusions</Link></p>
                        </CardContent>
                    </Card>
                </Grid>
            )
        });
    }

    return (
        <Grid container spacing={3}>
            {renderCards()}
        </Grid>
    )
}

export default connect(
    state => {
        const {
            data,
            isLoading,
            isLoaded,
            erreur
        }  = state.api.seriesEpStore;
        return { data, isLoading, isLoaded, erreur };
    },
    {
        getAPI,
        reinitializeGetAPI,
    }
)(SeriesEp);