import React, {useEffect} from "react";
import {getAPI, reinitializeGetAPI} from "../services/getApiRedux";
import {connect} from "react-redux";
import {API_FILMS, CONTEXT_FILMS} from "../constant/contextApi";
import Card from "@material-ui/core/Card";
import {CardContent, Grid } from "@material-ui/core";
import moment from "moment";
import 'moment/locale/fr';

function Films(props) {
    useEffect(() => {
        props.getAPI(API_FILMS, CONTEXT_FILMS);
    }, []);

    const renderCards = () => {
        if (!props.data) {
            return null;
        }
        return props.data.map(film => {

            moment.locale('fr');

            return (
                <Grid item xs={6} md={3} key={film.film.titre + film.debut}>
                    <Card>
                        <CardContent>
                            <p><b style={{textTransform: "uppercase"}}>{film.chaine}</b> - {film.film.titre}</p>
                            {film.film.image && <p style={{textAlign: 'center'}}><img src={film.film.image} style={{width: '100%', maxWidth: '150px'}} alt={film.film.titre} /></p>}
                            <i>{film.film.type}</i>
                            <p>{film.film.description}</p>
                            <p>
                                <u>Début :</u> {moment(film.debut, "YYYY-MM-DD HH:mm:ss").format("ddd D MMM HH:mm")}<br />
                                <u>Fin :</u> {moment(film.fin, "YYYY-MM-DD HH:mm:ss").format("ddd D MMM HH:mm")}
                            </p>
                        </CardContent>
                    </Card>
                </Grid>
            )
        });
    }

    return (
        <Grid container spacing={3}>
            {renderCards()}
        </Grid>
    )
}

export default connect(
    state => {
        const {
            data,
            isLoading,
            isLoaded,
            erreur
        }  = state.api.filmStore;
        return { data, isLoading, isLoaded, erreur };
    },
    {
        getAPI,
        reinitializeGetAPI,
    }
)(Films);