import axios from 'axios';
import {addMessage} from "./messagesRedux";
// ACTIONS
const HTTP_GET_REQUEST = 'HTTP_GET_REQUEST';
const HTTP_GET_SUCCESS = 'HTTP_GET_SUCCESS';
const HTTP_GET_FAILURE = 'HTTP_GET_FAILURE';
const HTTP_GET_CLEAR = 'HTTP_GET_CLEAR';

const httpRequest = context => ({
    type: context + HTTP_GET_REQUEST,
    payload: {},
    context,
});

const httpSuccess = (data, context) => ({
    type: context + HTTP_GET_SUCCESS,
    payload: data,
    context,
});

const httpFailure = (error, context) => ({
    type: context + HTTP_GET_FAILURE,
    payload: error,
    context,
});

const reinitialise = (store, context) => ({
    type: context + HTTP_GET_CLEAR,
    payload: store,
    context,
});
// Nettoie le store
export const reinitializeGetAPI = context => dispatch => {
    dispatch(reinitialise({}, context));
};

export const getAPI = (url, context) => dispatch => {
    dispatch(httpRequest(context));

    axios
        .get(url)
        .then(
            reponse => {dispatch(httpSuccess(reponse, context))},
            erreur => {
                dispatch(httpFailure(erreur, context));
                dispatch(addMessage(erreur.response.data.messages[0], erreur.response.data.status));
            }
        );
};

export const getAPIReducer = (context, state = {}, action = {}) => {
    switch (action.type) {
        case context + HTTP_GET_REQUEST:
            return { isLoading: true };
        case context + HTTP_GET_SUCCESS:
            return { ...state, isLoading: false, isLoaded: true, data: action.payload.data.payload.data };
        case context + HTTP_GET_FAILURE:
            return { ...state, isLoading: false, isLoaded: true, error: action.payload.response.data.messages };
        case context + HTTP_GET_CLEAR:
            return {};
        default:
            return state;
    }
};
