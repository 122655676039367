import React, {useEffect} from 'react';
import './App.css';
import {clearMessage} from "./services/messagesRedux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {BrowserRouter, Link, Switch, Route} from "react-router-dom";
import ToastMessage from "./components/ToastMessage";
import Films from "./scenes/Films";
import Container from "@material-ui/core/Container";
import SeriesEp from "./scenes/SeriesEp";
import SeriesEpId from "./scenes/SeriesEpId";
import Series from "./scenes/Series";
import SeriesId from "./scenes/SeriesId";

function App() {

  useEffect(() => {
    clearMessage();
  }, []);

  return (
      <BrowserRouter>
        <AppBar position="static">
          <Toolbar>
            <Link className="menuLink" to="/films">
              Films
            </Link>
            <Link className="menuLink" to="/series">
              Séries
            </Link>
            <Link className="menuLink" to="/series-ep">
              Séries EP
            </Link>
          </Toolbar>
        </AppBar>
        <main style={{marginTop: '64px', padding: '1rem', width: '100%'}}>
          <Container maxWidth="lg">
            <ToastMessage />
            <Switch>
              <Route path="/films">
                <Films />
              </Route>
              <Route exact path="/series">
                <Series />
              </Route>
              <Route path="/series/:id" component={SeriesId} />
              <Route exact path="/series-ep">
                <SeriesEp />
              </Route>
              <Route path="/series-ep/:id" component={SeriesEpId} />
            </Switch>
          </Container>
        </main>
      </BrowserRouter>
  );
}

export default App;
