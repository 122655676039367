import {combineReducers} from "redux";
import {getAPIReducer} from "../getApiRedux";
import {
    CONTEXT_FILMS,
    CONTEXT_SERIES,
    CONTEXT_SERIES_EP,
    CONTEXT_SERIES_EP_ID,
    CONTEXT_SERIES_ID
} from "../../constant/contextApi";

const api = combineReducers({
    filmStore: getAPIReducer.bind(null, CONTEXT_FILMS),
    seriesStore: getAPIReducer.bind(null, CONTEXT_SERIES),
    seriesIdStore: getAPIReducer.bind(null, CONTEXT_SERIES_ID),
    seriesEpStore: getAPIReducer.bind(null, CONTEXT_SERIES_EP),
    seriesEpIdStore: getAPIReducer.bind(null, CONTEXT_SERIES_EP_ID),
});

export default api;